import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, Title4Top, Input, Button, ErrorText, SuccessText, mobileThresholdPixels }
  from '../../components/styledComponents';

const Content = styled.div`
  margin: 50px auto;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 10px 0px;
  }
`;

const ForgottenPasswordRequestBlock = ({ isLoading, error, success, sendForgottenPasswordRequest, inputChange }) => (
  <Content>
    <Title4Top>Mot de passe oublié ?</Title4Top>
    {!success &&
      <div>
        <div>
          <Input
            type="email" placeholder="Votre adresse email"
            onChange={e => inputChange('email', e.target.value)}
          />
        </div>
        {isLoading ?
          <p>chargement</p> :
          <div>
            <Button type="button" onClick={() => sendForgottenPasswordRequest()}>
              Envoyer
            </Button>
          </div>
        }
        {error && <ErrorText>Email non reconnu</ErrorText>}
      </div>
    }
    {success &&
      <div>
        <SuccessText>Demande effectuée, un email vient de vous être envoyé.</SuccessText>
        <Link to={'/'}>revenir à l‘accueil</Link>
      </div>
    }
  </Content>
);

ForgottenPasswordRequestBlock.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  sendForgottenPasswordRequest: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
};

ForgottenPasswordRequestBlock.defaultProps = {
  isLoading: false,
  error: false,
  success: false,
};

export default ForgottenPasswordRequestBlock;
